/* @ngInject */
export default function UserCoursesResource(

  $resource,
) {
  return $resource(
    '/user_courses.json',
    { id: '@id' },
    {
      update: { method: 'PUT', url: '/user_courses/:id.json' },
      validateAndSaveZoomHostUser: { method: 'POST', url: '/:catalogId/live_session/validate_and_save_zoom_host_user.json' },
      getLiveSessionSettings: { method: 'GET', url: '/:catalogId/user_courses/live_session_settings.json' },
    },
  );
}
