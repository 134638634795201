import store from 'redux/store';
import { deleteLecturePage } from 'redux/actions/lecture-pages';

/* @ngInject */
export default function LecturePageBaseModelService(
  _,
  nvUtil,
  moment,
  CurrentCourseManager,
  LecturePagesResources,
) {
  class LecturePageBaseModel {
    constructor(attributes) {
      _.extend(this, attributes);
    }

    urlParams() {
      const params = {
        catalogId: this.catalogId,
        lecturePageId: this.lecturePage.id,
        lectureComponentType: nvUtil.toSnakeCase(this.type),
      };

      if (this.id) {
        params.id = this.id;
      }

      return params;
    }

    saveBasics() {
      const payload = _.pick(this, 'title', 'showTitle', 'releaseDate', 'viewOptions', 'coverImage', 'cardViewImage');
      return LecturePagesResources.update({ catalogId: this.catalogId, id: this.id },
        { lecturePage: payload })
        .$promise.then((response) => {
          if (response.result.hasStructuralIssues != null) {
            this.hasStructuralIssues = response.result.hasStructuralIssues;
            this.hasTimelineIssues = response.result.hasTimelineIssues;
          }
          return response.result;
        });
    }

    duplicateLecturePage() {
      return LecturePagesResources.duplicateLecturePage({
        catalogId: this.catalogId,
        id: this.id,
      }, {
        releaseDate: moment().add(1, 'months').endOf('day').toISOString(),
      }).$promise
        .then((response) => response.result);
    }

    // this is for removing cached data for lecture pages
    deleteLecturePage() {
      return store.dispatch(deleteLecturePage({
        catalogId: this.catalogId, lecturePageId: this.id,
      })).then((response) => {
        if (response?.payload) this.cleanLecturePage();
        return response;
      });
    }

    // It cleans flags related to the current Lecture Page
    cleanLecturePage() {
      if (CurrentCourseManager.course?.profileRequirement?.lecturePageId === this.id) {
        CurrentCourseManager.course.removeProfileRequirement();
      }

      if (CurrentCourseManager.course?.courseLongTeamSet?.lecturePageId === this.id) {
        CurrentCourseManager.course.removeTeamSet();
      }

      if (CurrentCourseManager.course?.groupTeamSet?.lecturePageId === this.id) {
        CurrentCourseManager.course.removeGroupTeamSet();
      }
    }
  }

  return LecturePageBaseModel;
}
