import { css } from '@emotion/react';
import { useContext } from 'react';
import { AngularServicesContext } from 'react-app';
import { useLectureComponentContext } from 'components/lecture-component-provider';
import { gray1, gray2, gray4, gray5, gray6, hexToRgbaString, white } from 'styles/global_defaults/colors';
import { halfSpacing, quarterSpacing, standardSpacing } from 'styles/global_defaults/scaffolding';
import { semiBoldFontWeight, textSmallFontSize, textSmallLineHeight } from 'styles/global_defaults/fonts';
import { ShapeOptions } from './meet-and-greet-lecture-component';


type PersonCardProps = {
  person: {
    profilePictureMedium?: string,
    fullName?: string,
    id?: number,
    initials?: string,
    answer?: string,
    empty?: boolean,
  },
  shape: ShapeOptions,
  columnsCount: number,
  questionIcon: {
    icon?: string,
    iconColor?: string,
    cdnUrl?: string,
  },
  showAnswer: boolean,
};

const MeetAndGreetPersonCard = ({
  person,
  shape,
  columnsCount,
  questionIcon,
  showAnswer,
}: PersonCardProps) => {
  const { $state } = useContext(AngularServicesContext);
  const { catalogId } = useLectureComponentContext();

  const personStyles = css`
    min-width: 100px;
    aspect-ratio: 1;
    cursor: pointer;
    position: relative;

    .shape {
      ${shape === ShapeOptions.ROUNDED_SQUARE && `border-radius: ${standardSpacing}px;`}
      ${shape === ShapeOptions.CIRCLE && 'border-radius: 50%;'}
    }

    .empty {
      color: ${gray2};
      background-color: ${gray5};
    }

    .initials {
      font-size: 50px;
      color: ${white};
      background-color: ${gray4};
    }

    .hover-info {
      display: none;
      width: 100%;
      padding: ${halfSpacing}px;
      background: ${hexToRgbaString(white, 0.95)};
      position: absolute;
      bottom: 0;

      .person-name {
        font-weight: bold;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .person-info {
        font-weight: ${semiBoldFontWeight};
        line-height: ${textSmallLineHeight}px;
        font-size: ${textSmallFontSize}px;
        color: ${gray1};
        padding-top: ${quarterSpacing}px;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: ${columnsCount === 6 ? 2 : 3};
        overflow: hidden;
      }
    }

    &:hover .hover-info {
      display: block;
    }
  `;

  let content = <div className='initials shape h-100 d-flex align-items-center justify-content-center'>{person.initials}</div>;

  if (person.empty) {
    content = <div className='empty shape h-100 d-flex align-items-center justify-content-center'>N/A</div>;
  }

  if (person.profilePictureMedium) {
    const pictureStyles = css`
      img {
        position: absolute;
        height: 100%;
        width: 100%;
        object-fit: cover;
      }

      @keyframes shineAnimation {
        0% {
          background-position: 0% 50%;
        }

        100% {
          background-position: -100% 50%;
        }
      }

      .loading-panel {
        width: 100%;
        height: 100%;
        background: ${gray6};
        padding: 20%;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .loading-bar {
        width: 100%;
        height: ${quarterSpacing}px;
        margin-bottom: 18px;
        background: linear-gradient(90deg, ${gray5} 35%, ${gray4} 45%, ${gray5} 55%);
        background-size: 600% 600%;
        animation: shineAnimation 1.5s infinite;

        &:last-of-type {
          width: 60%;
        }
      }
    `;

    content = (
      <div css={pictureStyles} className='h-100'>
        <img
          className='shape'
          src={person.profilePictureMedium}
          alt={person.fullName}
        />
        <div id={`id-loading-${person.profilePictureMedium}`} className='shape loading-panel'>
          <div className='loading-bar' />
          <div className='loading-bar' />
          <div className='loading-bar' />
        </div>
      </div>
    );
  }

  return (
    <div
      data-qa={`meet-and-greet-link-to-profile-${person.id}`}
      css={personStyles}
      className='person'
      onClick={() => person.id && $state.go('learner-profile-modal', { catalogId, userId: person.id }, '_blank')}
    >
      {content}
      {person.fullName && (
        <div className='hover-info'>
          <div className='person-name'>{person.fullName}</div>
          {(showAnswer && person.answer) && (
            <div className='d-flex'>
              {questionIcon?.icon && (<div className={`mt-1 mr-1 icon icon-smallest icon-${questionIcon.icon}`} css={css`color: ${questionIcon.iconColor};`} />)
                || questionIcon?.cdnUrl && (<img className='mt-1 mr-1' css={css`max-height: 16px; max-width: 16px;`} src={questionIcon.cdnUrl} alt='' />)}
              <div className='person-info'>{person.answer}</div>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default MeetAndGreetPersonCard;

