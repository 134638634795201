// TODO: We might move some of the component actions from actions/lecture-pages.ts into this file

import axios from 'axios';
import t from 'react-translate';
import { DeepPartial } from 'utility-types';
import { PrepareAction, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { Result } from 'redux/schemas/api';
import { LectureComponent, NLectureComponent, TemporaryLectureComponent, ComponentType, LectureComponentCommon, ComponentTrueType } from 'redux/schemas/models/lecture-component';
import { decamelize, decamelizeKeys } from 'humps';
import { NLecturePage } from 'redux/schemas/models/lecture-page';
import { ProfileRequirement } from 'redux/schemas/models/profile-requirement';
import { ActivityKey, ActivityProgress } from 'redux/schemas/models/activity';
import { EXERCISE_DEPENDENT_COMPONENTS } from 'redux/selectors/course';
import { contains } from 'underscore';
import { deepSet, objectToFormData } from 'shared/utils';
import { cloneDeepSerializable } from 'redux/store';
import { LectureComponentDropdownMode } from 'lecture_pages/directives/components/lecture-component-edit-dropdown';
import { AutoGenerateParams } from 'redux/schemas/app/lecture-component';
import { AlertMessageType } from 'redux/schemas/app/alert-message';
import { AutoGenerated } from 'redux/schemas/models/video';
import { RootState } from 'redux/schemas';
import { getCourseExercises, setBottomBarVisibility, setLeftPanelView, setNewComponentIndex } from './lecture-pages';
import { getAvailablePracticeActivities } from './video-practice-feedback';
import { addAlertMessage } from './alert-messages';
import { canConvertToCollectionLecture } from './collections';

// TODO: Move to actions/lecture-pages.ts
type LecturePageAPIProps = {
  catalogId: string,
  lecturePageId: number,
};

type LectureComponentAPIProps = {
  /** The component being modified. This is required because component urls include the component type */
  lectureComponent: DeepPartial<LectureComponent> | Partial<NLectureComponent>
} & LecturePageAPIProps;

type ActionToLectureComponentAPIProps = {
  toLecturePageId: string,
  newDestinationIndex: string,
} & LectureComponentAPIProps;

/** Constructs a URL like http://acumen.stage.novoed.com/nathan-test-course/lecture_pages/863029 */
const lecturePageUrl = (params: LecturePageAPIProps) => `/${params.catalogId ?? 'content_management'}/lecture_pages/${params.lecturePageId}`;

/** Constructs a URL like http://acumen.stage.novoed.com/nathan-test-course/lecture_pages/863029/header_image_lecture_component/816154, with the /816154 being optinally added if `includeId` is true */
const lectureComponentUrl = (params: LectureComponentAPIProps, includeId?: boolean) => `${lecturePageUrl(params)}/${decamelize(params.lectureComponent.type)}${includeId ? `/${params.lectureComponent.id}` : ''}`;

/**
 * Fetching the exercises API if the action performed lecture component is an
 * exercise or exercise dependent component. Used to enable/disable the new
 * component add buttons in lecture page LHS.
 */
const fetchCourseExercisesIfNeeded = (params, thunkAPI) => {
  if (contains([...EXERCISE_DEPENDENT_COMPONENTS, ComponentType.EXERCISE], params.lectureComponent.type)) {
    thunkAPI.dispatch(getCourseExercises({ catalogId: params.catalogId }));
  }
};

const checkContentConvertToCollectionLecture = (params, thunkAPI) => {
  thunkAPI.dispatch(canConvertToCollectionLecture({
    id: params.lecturePageId,
  }));
};


const fetchAvailablePracticeActivities = (params, thunkAPI) => {
  if (params.lectureComponent.type === ComponentType.VIDEO_PRACTICE
    || params.lectureComponent.type === ComponentType.VIDEO_PRACTICE_FEEDBACK
    || params.lectureComponent.type === ComponentType.VIDEO_PRACTICE_SKILLS_FEEDBACK) {
    thunkAPI.dispatch(getAvailablePracticeActivities({ catalogId: params.catalogId }));
  }
};

// eslint-disable-next-line import/prefer-default-export
export const addNewComponent = createAsyncThunk('ADD_NEW_LECTURE_COMPONENT',
  (params: {
    index: number,
    file?: File,
    filePath?: string,
    temporaryComponentId?: string,
    onUploadProgress?: (progressEvent: any) => void,
  } & LectureComponentAPIProps, thunkApi) => {
    // TODO: Handle this outside of this action
    thunkApi.dispatch(setLeftPanelView('outline'));
    thunkApi.dispatch(setBottomBarVisibility(true));
    thunkApi.dispatch(setNewComponentIndex(-1));

    let payload: any = {
      lectureComponent: {
        ...params.lectureComponent,
        index: params.index,
      },
    };

    if (params.file) {
      payload = decamelizeKeys(payload);
      const decamelizedPath = params.filePath.split('.').map((property) => decamelize(property)).join('.');

      deepSet(payload.lecture_component, decamelizedPath, params.file);

      payload = objectToFormData(payload);
    }

    return axios.post<Result<LectureComponent>>(
      `${lectureComponentUrl(params, false)}`,
      payload,
      { onUploadProgress: params.onUploadProgress },
      // TODO: Fire getCourseLecturePageTimeline action
    ).then(response => {
      fetchAvailablePracticeActivities(params, thunkApi);
      fetchCourseExercisesIfNeeded(params, thunkApi);

      const lectureComponent = response.data.result;

      if (lectureComponent.type === ComponentType.VIDEO) {
        const hasAutoGenerateProcess = lectureComponent.lectureVideos[0]?.video?.autoGenerateCaption && lectureComponent.lectureVideos[0]?.video?.autoGenerateTranscript;
        const state: RootState = thunkApi.getState() as RootState;
        const { isVideoFromLibrary } = state.app;

        if (hasAutoGenerateProcess && !isVideoFromLibrary) {
          // Display the info message
          thunkApi.dispatch(
            addAlertMessage({
              type: AlertMessageType.INFO,
              header: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.INFO.TITLE.BOTH(),
              message: t.LECTURE_VIDEO.AUTO_GENERATED_TRANSCRIPT_AND_CAPTIONS.INFO.MESSAGE.EMAIL(),
            }),
          );

          // Reseting the auto generate process state
          thunkApi.dispatch(setAutoGenerateProcess({
            componentType: ComponentType.VIDEO,
            params: {
              isEnabled: null,
              isCaptionEnabled: null,
              isTranscriptEnabled: null,
            },
          }));
        }

        if (isVideoFromLibrary) {
          thunkApi.dispatch(setIsVideoFromLibrary({
            isVideoFromLibrary: false,
          }));
        }
      }

      checkContentConvertToCollectionLecture(params, thunkApi);
      return lectureComponent;
    }).catch(e => {
      if (e?.response?.data?.error?.code) {
        throw { ...e, message: e.response.data.error.code };
      }
      throw e;
    });
  });

export const addTemporaryComponent = createAction<TemporaryLectureComponent>('ADD_TEMPORARY_COMPONENT');

export const clearTemporaryComponents = createAction('CLEAR_TEMPORARY_COMPONENTS');

export const deleteLectureComponent = createAsyncThunk(
  'DELETE_LECTURE_COMPONENT',
  (params: LectureComponentAPIProps, thunkAPI) => (
    axios.delete<Result<boolean>>(lectureComponentUrl(params, true))
      .then(response => {
        fetchAvailablePracticeActivities(params, thunkAPI);
        fetchCourseExercisesIfNeeded(params, thunkAPI);
        checkContentConvertToCollectionLecture(params, thunkAPI);
        return response.data.result;
      })
  ),
);

export const setCopyOrMoveAction = createAction<{ action: LectureComponentDropdownMode, component: NLectureComponent }>('SET_COPY_OR_MOVE_ACTION');

export const copyLectureComponent = createAsyncThunk('COPY_LECTURE_COMPONENT', (params: ActionToLectureComponentAPIProps) => axios.post<Result<LectureComponent>>(`${lectureComponentUrl(params, true)}/clone_to?to_lecture_page_id=${params.toLecturePageId}&new_destination_index=${params.newDestinationIndex} `).then(response => response.data.result));
/** This is for moving LCs across pages, not up/down within a single page */
export const moveLectureComponent = createAsyncThunk('MOVE_LECTURE_COMPONENT', (params: ActionToLectureComponentAPIProps) => axios.put<Result<LectureComponent>>(`${lectureComponentUrl(params, true)}/move_to?to_lecture_page_id=${params.toLecturePageId}&new_destination_index=${params.newDestinationIndex} `).then(response => response.data.result));

/** This is for reording LCs on a single page, not between pages. This is synchronous because
 * we do not save the new ordering until Reorder mode is exited */
export const reorderLectureComponent = createAction<{
  newIndex: number,
} & LectureComponentAPIProps>('REORDER_LECTURE_COMPONENT');

export const resetLectureComponentReorder = createAction<{
  lecturePageId: number,
}>('RESET_LECTURE_COMPONENT_REORDER');

/** This is the API request used to save changes made via reorderLectureComponent() upon existing the
 * reorder mode */
export const updateLectureComponentOrder = createAsyncThunk('UPDATE_LECTURE_COMPONENT_ORDER',
  async (params: {
    /** The ordered component ID list for the new ordering */
    lectureComponents: NLecturePage['lectureComponents'],
  } & LecturePageAPIProps) => axios.post<Result<boolean>>(`${lecturePageUrl(params)}/update_lecture_component_order.json`, {
    lectureComponents: params.lectureComponents.map((lcID, i) => ({ id: lcID, index: i })),
  }).then(response => response.data.result));

export const setCurrentVideoId = createAction<{
  videoId: number,
}>('SET_CURRENT_VIDEO_ID');

export const setIsVideoFromLibrary = createAction<{
  isVideoFromLibrary: boolean,
}>('SET_IS_VIDEO_FROM_LIBRARY');

export const setAutoGenerateProcess = createAction<{
  componentType: ComponentType,
  params: AutoGenerateParams,
}>('SET_AUTO_GENERATE_PROCESS');

export const updateAutoGeneratedTranscript = createAction<{
  params: AutoGenerated['transcript'],
}>('UPDATE_AUTO_GENERATED_TRANSCRIPT');

export const updateAutoGeneratedCaption = createAction<{
  params: AutoGenerated['caption'],
}>('UPDATE_AUTO_GENERATED_CAPTION');

export const updateAutoTranslatedCaption = createAction<{
  params: AutoGenerated['caption'],
}>('UPDATE_AUTO_TRANSLATED_CAPTION');

// TODO: These should probably live in different acitons files specific to the relevant components

export const setCurrentCourseGroupTeamSet = createAction<{
  groupTeamSet: any,
}>('SET_CURRENT_COURSE_GROUP_TEAM_SET');

export const unsetCurrentCourseGroupTeamSet = createAction('UNSET_CURRENT_COURSE_GROUP_TEAM_SET');

export const deleteLectureComponentsByType = createAction<{
  lecturePageId: number,
  type: ComponentType,
}>('deleteLectureComponentsByType');

export const setCurrentCourseCourseLongTeamSet = createAction<{
  courseLongTeamSet: any,
}>('SET_CURRENT_COURSE_COURSE_LONG_TEAM_SET');

export const unsetCurrentCourseCourseLongTeamSet = createAction('UNSET_CURRENT_COURSE_COURSE_LONG_TEAM_SET');

export const setCurrentCourseProfileRequirement = createAction<{
  profileRequirement: ProfileRequirement,
}>('SET_CURRENT_COURSE_PROFILE_REQUIREMENT');

export const unsetCurrentCourseProfileRequirement = createAction('UNSET_CURRENT_COURSE_PROFILE_REQUIREMENT');

export const setActivityProgress = createAction<{
  activityKey: ActivityKey,
  activityPayloadId: number,
  progress: ActivityProgress,
  pointsReceived?: number,
}>('SET_ACTIVITY_PROGRESS');

/**
 * Redux action to deep merge any AngularJS lecture component data into any
 * redux lecture component.
 */
export const updateLectureComponentFromAngular = createAction<PrepareAction<DeepPartial<LectureComponentCommon<ComponentTrueType>>>>(
  'UPDATE_LECTURE_COMPONENT_FROM_ANGULAR',
  (lectureComponent) => ({
    // We need to make sure that data that comes from angular and goes to redux
    // gets cloned because Immer (dependency of redux toolkit) makes it not
    // extensible and as in angular we mutate properties we don't have to mess
    // with the original input reference to prevent breaking the app.
    // We are using cloneDeepSerializable given that as this action gets
    // dispatched from angular, we sometimes pass model instances so we only
    // clone serializable properties.
    payload: cloneDeepSerializable(lectureComponent),
  }),
);

export const resetNewComponent = createAction('RESET_NEW_COMPONENT');
