import { config } from '../../../config/config.json';

const OE_HASH_BANG = '/oe/#!/';

export const RailsRoutes = {
  /** Outside of Course * */
  newTransactionPath: (catalogId) => `/${catalogId}/transactions/new`,
  termsPath: (catalogId) => `/${catalogId}/home/terms`,
  statementOfAccomplishment: (catalogId) => `/${catalogId}/my_statement`,
  statementOfAccomplishmentDownload: (catalogId, userId) => `/${catalogId}/statement_template/download?user_id=${userId}&download=true`,
  statementOfAccomplishmentCustomDownload: (catalogId, userId) => `/${catalogId}/statement_template/download_custom?user_id=${userId}&download=true`,

  /* Exercises */
  questionSetNewPath: (catalogId, ownerId, ownerType, type) => `/${catalogId}/question_sets/new?owner_id=${ownerId}&owner_type=${ownerType}&type=${type}`,
  editSubmissionFormatPath: (catalogId, exerciseId) => `/${catalogId}/exercises/${exerciseId}/edit_submission_format`,
  editTeamsPath: (catalogId, teamSetId, returnToTeamFacilitation) => `/${catalogId}${OE_HASH_BANG}teamsets/${teamSetId}/manual${returnToTeamFacilitation ? '?origin=facilitation' : ''}`,
  editGroupProfileQuestionsPath: (catalogId, teamSetId) => `/${catalogId}/team_sets/${teamSetId}/edit_course_long_team_profile_questions`,
  editAdvancedPath: (catalogId, exerciseId) => `/${catalogId}/exercises/${exerciseId}/edit_advanced`,
  editPeerEvaluationsPath: (catalogId, exerciseId) => `/${catalogId}${OE_HASH_BANG}exercises/${exerciseId}/edit_peer_info`,

  /* Dashboard */
  editQuestionSetPath: (catalogId, questionSetId) => `/${catalogId}/question_sets/${questionSetId}/edit`,
  adminDashboardPath: (catalogId, showPrerequisites) => {
    let url = `/${catalogId}/dashboard`;

    if (showPrerequisites) {
      url += '?show_prerequisites=true';
    }

    return url;
  },
  contactStudentsPath: (catalogId) => `/${catalogId}/dashboard?tab=announcements`,
  analyticsPath: (catalogId) => `/${catalogId}${OE_HASH_BANG}dashboard/analytics`,

  /* Log out */
  logOutPath: () => '/users/sign_out',
  downloadInstitutionCsv: (institutionId, courseIds) => `/courses/download_csv?institution_id=${institutionId}&course_ids=${courseIds}`,

  conversationsPath: () => `${OE_HASH_BANG}conversations`,

  conversationIsolatedPath: (catalogId, conversationId) => `${OE_HASH_BANG}conversations/${conversationId}`,
  deleteCoursePath: (catalogId) => `/${catalogId}?show_course_deletion_modal=true`,
  courseFlyerEditPath: (catalogId) => `/${catalogId}/dashboard?tab=flyer_page`,
  programFlyerEditPath: (catalogId) => `/${catalogId}?edit=true`,

  /* Zoom And MS team user OAuth installation URL */
  zoomOAuthInstallationUrl: (catalogId, lecturePageId) => `/${catalogId}/user_courses/authenticate_zoom_oauth?lecture_page_id=${lecturePageId}`,
  msTeamOAuthInstallationUrl: (catalogId, lecturePageId) => `/${catalogId}/user_courses/authenticate_microsoft_oauth?lecture_page_id=${lecturePageId}`,

  accountZoomOauthUrl: (institutionId) => `institutions/${institutionId}/authenticate_zoom_oauth`,

  authenticateMicrosoftCalendarOauthUrl: (institutionId) => `institutions/${institutionId}/authenticate_microsoft_calendar_oauth`,
  courseAdminDashboardTabPath: (catalogId, tab) => `/${catalogId}/dashboard?tab=${tab}`,
  courseAdminDashboardTabSectionPath: (catalogId, tab, sectionId) => `/${catalogId}/dashboard?tab=${tab}&section_id=${sectionId}`,
};

/* @ngInject */
export default function RailsRoutesFactory() {
  return RailsRoutes;
}
