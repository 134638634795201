/* @ngInject */
export default function UserCourseModelService(
  _,
  $q,
  UserCoursesResource,
) {
  const LIVE_SESSION_SOURCES = {
    ZOOM_USER_LEVEL: 'zoomOauth',
    ZOOM_ACCOUNT_LEVEL: 'zoomAccountOauth',
    MS_TEAMS_USER_LEVEL: 'microsoftGraphOauth',
  };

  class UserCourseModel {
    constructor(attributes) {
      _.extend(this, attributes);

      this.LIVE_SESSION_SOURCES = LIVE_SESSION_SOURCES;
    }

    validateAndSaveZoomHostUser(host) {
      return UserCoursesResource.validateAndSaveZoomHostUser({ catalogId: this.catalogId }, { hostUserId: host })
        .$promise.then((response) => {
          const isValidHost = response.result.isValid;
          if (isValidHost) {
            this.zoomUserId = host;
          }

          return isValidHost;
        });
    }

    getLiveSessionSettings() {
      if (this.integrationSettings) {
        return $q.when(this.integrationSettings);
      }

      return UserCoursesResource.getLiveSessionSettings({ catalogId: this.catalogId })
        .$promise.then((response) => {
          const returnResponse = {};
          if (response.result) {
            _.map(response.result, (integration, key) => {
              if (key === this.LIVE_SESSION_SOURCES.ZOOM_ACCOUNT_LEVEL
              ) {
                returnResponse.ZoomLiveSession = {
                  source: key,
                  authenticated: integration?.authenticationStatus === 'authenticated',
                  zoomUserId: response.result.zoomUserId,
                };
              }
              if (
                key === this.LIVE_SESSION_SOURCES.ZOOM_USER_LEVEL
                || key === this.LIVE_SESSION_SOURCES.MS_TEAMS_USER_LEVEL
              ) {
                returnResponse[key === this.LIVE_SESSION_SOURCES.ZOOM_USER_LEVEL ? 'ZoomLiveSession' : 'TeamsLiveSession'] = {
                  source: key,
                  authenticated: integration?.authenticationStatus === 'authenticated',
                  email: integration?.authenticationEmail,
                };
              }
            });
            returnResponse.authenticationStatus = response.result.authenticationStatus;
          }
          this.integrationSettings = returnResponse;
          return returnResponse;
        });
    }
  }

  return UserCourseModel;
}
