export enum AlertMessageType {
  SUCCESS = 'success',
  WARNING = 'warning',
  ERROR = 'error',
  INFO = 'info',
  LIVE_EVENT = 'liveEvent',
}

export interface AlertMessageProps {
  type: AlertMessageType;
  message?: string;
  header?: string;
  iconClass?: string;
  button?: JSX.Element;
  duration?: number;
  onClose?: (...args: any[]) => void;
}
