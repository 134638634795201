import { createElement } from 'react';
import EditNameAndEmailModal from 'institutions/components/edit-name-and-email/edit-modal';
import store, { cloneDeepSerializable } from 'redux/store';
import { NameAndEmailFormSource } from 'redux/schemas/models/user';
import { PARSING_MODES } from './upload-users-csv-controller';
import { MANAGE_MENTEES_MODAL_VIEWS } from './manage-mentees-modal-controller';
import { removeUserEnrollment } from '../../redux/actions/users';

/* @ngInject */
export default function UserManagementMainCtrl(
  $scope,
  $state,
  $translate,
  $uibModal,
  AlertMessages,
  ConfirmationOverlays,
  CourseRolesManager,
  CurrentCourseManager,
  CurrentPermissionsManager,
  CurrentUserManager,
  InstitutionsManager,
  PasswordsResource,
  RolesService,
  UserManagementResources,
  UserModel,
  config,
  _,
  amMoment,
  nvCurrentPage,
) {
  // The # of user results we retrieve from API on each page
  const TABLE_PAGE_SIZE = 30;

  // An enum for the type of users shown in the results table
  const USER_TYPE_FILTERS = {
    ADMINS: 0,
    LEARNERS: 1,
    SEARCH: 2,
    MENTORS: 3,
  };

  class UserManagementMainController {
    constructor() {
      // Current type of user to show on the page
      this.userTypeFilter = nvCurrentPage.getCurrentStateData().userTypeFilter;

      if (!this.userTypeFilter) {
        this.userTypeFilter = USER_TYPE_FILTERS.ADMINS;
      }

      this.PARSING_MODES = PARSING_MODES;

      // Gives full read and edit access to all User Mgmt buttons and tabs
      this.isFullAccess = CurrentPermissionsManager.isConfigAndRegistrationRole();

      // Edit Name and Email
      // If this is false, e don't show anything in the menu
      this.showEditNameAndEmailMenuItem = (!InstitutionsManager.institution.ssoLogin || InstitutionsManager.institution.enableBasicSsoInfoEdit);
      // With more than Learner Registration, you can edit name and email even if user has multiple enrollments
      this.moreThanLearnerRegistration = (CurrentPermissionsManager.isConfigAndRegistrationRole()
        || CurrentPermissionsManager.hasOrgAdminPermissions()
        || CurrentPermissionsManager.hasCourseManagerPermissions());
      // If you only have Learner Registration, editing name and email will be disabled if the user has multiple enrollments
      this.onlyLearnerRegistration = CurrentPermissionsManager.isLearnerRegistrationRole() && !this.moreThanLearnerRegistration;

      // Disables clicking the add user and search buttons
      this.isManageUsersEnabled = CurrentCourseManager.course.isJourney ? (CurrentPermissionsManager.hasOrgAdminPermissions() || CurrentPermissionsManager.hasFullCourseAdminPermissions() || CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isLearnerRegistrationRole()) : (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole());

      this.isSearchEnabled = CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole();
      this.isMentorLinkEnabled = CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor();
      // Limits search filtering to Admin roles
      this.canOnlyFilterByAdmins = !(CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole());
      this.canOnlyFilterByLearners = !CurrentPermissionsManager.hasFullCourseAdminPermissions();
      this.canOnlySetLearnerRoles = !CurrentPermissionsManager.isConfigAndRegistrationRole();
      // Limits the manage users button options to just mentee assignment
      this.canOnlyAssignMentees = !(CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isLearnerRegistrationRole());
      this.canBulkUnenroll = CurrentPermissionsManager.isConfigAndRegistrationRole();
      this.hideBulkUnroll = !CurrentPermissionsManager.hasFullCourseAdminPermissions();
      this.hideBulkAssignMentees = !CurrentPermissionsManager.hasFullCourseAdminPermissions();
      // Remove the learner tab from the tab set
      const learnerTabIsHidden = !(CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || CurrentPermissionsManager.isLearnerRegistrationRole());
      const adminTabIsHidden = !CurrentPermissionsManager.hasFullCourseAdminPermissions();
      const mentorTabIsHidden = !CurrentPermissionsManager.hasFullCourseAdminPermissions();

      // Caches of users for each type. Can be marked 'outdated' to trigger clearing the cache on the next table load
      this.initUsers();

      this.roleFilter = null; // Current filter drop down value
      this.nonSearchTabsVisible = true;
      this.viewingSearchResults = false;
      this.searchResultsCount = 0;

      // These control which loading states are visible, either the 'Loading: Users' (etc) banner or an in-table dummy animation
      this.isHardReloading = true; // Whether we're doing a 'hard' reload of all data in the table, like when switching states
      this.isLoadingMoreResults = false; // Whether we're loading more table data but without resetting the entire experience like with isHardReloading

      // TODO: I think this and `viewingSearchResults` accomplish nearly the same thing and are always
      // (hopefully?) the same value. Remove one of them
      this.isInSearch = false;
      this.searchQuery = '';
      this.isEditing = false;
      this.editingId = -1;
      this.courseRoleDraft = CourseRolesManager.noRolesRole;
      this.preFilterState = CurrentPermissionsManager.hasFullCourseAdminPermissions() ? this.getTabState(CurrentCourseManager.course.isJourney ? 'admins' : 'course-admins') : this.getTabState('learners'); // TODO: This might be able to be combined with lastTabState

      this.userListedTooltip = $translate.instant('USER_MANAGEMENT.USER_LISTED_TOOLTIP', {
        teachingTeamAlias: CurrentCourseManager.course.teachingTeamName.singularizedTitleized,
      });

      this.$state = $state;
      this.$uibModal = $uibModal;
      this.amMoment = amMoment;
      this.nvCurrentPage = nvCurrentPage;
      this.AlertMessages = AlertMessages;
      this.ConfirmationOverlays = ConfirmationOverlays;
      this.CourseRolesManager = CourseRolesManager;
      this.CurrentCourseManager = CurrentCourseManager;
      this.CurrentPermissionsManager = CurrentPermissionsManager;
      this.CurrentUserManager = CurrentUserManager;
      this.InstitutionsManager = InstitutionsManager;
      this.RolesService = RolesService;
      this.UserManagementResources = UserManagementResources;
      this.UserModel = UserModel;
      this.config = config;

      this.getUsersForInfiniteScroll = {
        get: _.bind(this.loadUsersInfiniteScroll, this),
      };

      const learnerTab = {
        name: CurrentCourseManager.course.learnersName.pluralizedTitleized,
        count: 0,
        state: this.getTabState('learners'),
      };
      const adminTab = {
        name: 'USER_MANAGEMENT.COURSE_ADMINS',
        count: 0,
        state: this.getTabState(CurrentCourseManager.course.isJourney ? 'admins' : 'course-admins'),
        translateValues: CurrentCourseManager.course.getAliases(),
      };
      const mentorTab = {
        name: 'USER_MANAGEMENT.MENTOR_MONITOR_USERS',
        count: 0,
        state: this.getTabState('mentors'),
        translateValues: CurrentCourseManager.course.getAliases(),
      };

      this.nonSearchTabs = [];
      if (!learnerTabIsHidden) {
        this.nonSearchTabs.push(learnerTab);
      }

      if (!adminTabIsHidden) {
        this.nonSearchTabs.push(adminTab);
      }

      if (!mentorTabIsHidden && !CurrentCourseManager.course.isJourney) {
        this.nonSearchTabs.push(mentorTab);
      }

      this.learnerTab = learnerTab;
      this.adminTab = adminTab;
      this.mentorTab = mentorTab;

      this.resultsTab = [
        { name: 'SEARCH.RESULTS', count: 0, state: this.getTabState('search') },
      ];

      if ($state.params.query) {
        this.searchQuery = $state.params.query;
      }

      if ($state.current.name === this.getTabState('search')) {
        this.isInSearch = true;
        this.switchToSearchResults();
      }

      this.initFromTypeFilter();
      this.loadUserCounts();

      $scope.$on('$stateChangeSuccess', (event, toState, toParams, fromState, fromParams) => {
        if (this.$state.includes(CurrentCourseManager.course.isJourney ? 'learning-journey-user-management' : 'user-management')) {
          this.updateFromState(toState, toParams);
        }
      });

      // When selecting a role filter in the filters dropdown
      this.onRolesRadioChanged = function (role) {
        this.preFilterState = this.$state.current.name;
        this.$state.go(this.getTabState('search'), { roleFilter: role.id, initialState: null });
      }.bind(this);

      this.onEditingRoleChanged = function (role) {
        this.courseRoleDraft = role;
      }.bind(this);

      /** Fired whenver a non search tab is clicked, after the state change */
      this.onNavTabChanged = (stateName) => {
        this.lastTabState = stateName;
      };

      this.updateFromState($state.current, $state.params);

      const EditNameAndEmailModalComponent = () => createElement(
        EditNameAndEmailModal,
        {
          source: NameAndEmailFormSource.COURSE,
          forwardShowModal: (func) => {
            this.openEditNameAndEmail = func;
          },
        },
      );

      $scope.EditNameAndEmailModal = EditNameAndEmailModalComponent;
    }

    getTabState(tab) {
      return `${CurrentCourseManager.course.isJourney ? 'learning-journey-' : ''}user-management-${tab}`;
    }

    usersInCourse() {
      return _.some(this.nonSearchTabs, (tab) => tab.count);
    }

    initUsers() {
      const users = [];
      for (let i = 0; i < _.keys(USER_TYPE_FILTERS).length; i += 1) {
        users.push({ data: [], outdated: true });
      }

      this.users = users;
    }

    // Used in template files to calculate the # of users in the current set.
    getUsers() {
      return this.users[this.userTypeFilter].data;
    }

    initFromTypeFilter() {
      this.userTypeFilter = nvCurrentPage.getCurrentStateData().userTypeFilter;
    }

    updateFromState(state, params) {
      if (params.initialState) {
        this.lastTabState = null;
        this.roleFilter = null;
        this.switchToNonSearch({ roleFilter: undefined, initialState: false });
        return;
      }

      // Guard against being in the search state without a query defined. Gets triggered when navigating to user-management/learners when
      // search results are open.
      if (!params.query && this.isInSearch) {
        this.switchToNonSearch();
        this.refreshTable();
        return;
      }

      this.refreshTable(params.forceReload);
      this.roleFilter = Object.prototype.hasOwnProperty.call(params, 'roleFilter') ? params.roleFilter : null;

      // TODO: Band-aid for having to pass roleFilter as 'undefined' upon clearing filter
      if (this.roleFilter === undefined) {
        this.roleFilter = null;
      }

      this.isEditEnabled = CurrentPermissionsManager.isConfigAndRegistrationRole()
      || (CurrentPermissionsManager.isInstructor() && (state.name === this.getTabState('learners') || state.name === this.getTabState('mentors')))
      || (CurrentPermissionsManager.isLearnerRegistrationRole() && (state.name === this.getTabState('learners')));

      this.nonSearchTabsVisible = this.roleFilter == null && state.name !== this.getTabState('search');
    }

    refreshTable(forceReload) {
      // Force requerying for data, including requesting the student & admin counts.
      // This function is becoming more of a 'completely reload the user mgmt view', may need reworked
      if (forceReload) {
        _.map(this.users, (users) => {
          users.outdated = true;
        });

        this.loadUserCounts();
      }

      this.isHardReloading = true;
      this.initFromTypeFilter();
      if (this.nvCurrentPage.uiScrollAdapter) {
        this.nvCurrentPage.uiScrollAdapter.reload();
      }
    }

    resetUsers() {
      this.searchResults = [];
      if (this.viewingSearchResults) {
        this.searchResults = [];
      } else {
        this.users[this.userTypeFilter] = [];
      }
    }

    loadUserCounts() {
      this.CurrentCourseManager.getEnrolledUserCounts().then((response) => {
        this.learnerTab.count = response.result.enrolledUsersCount;
        this.adminTab.count = response.result.enrolledAdminCount;
        this.mentorTab.count = response.result.enrolledMentorsCount;
      });
    }

    /**
     * See 'Datasource' at https://github.com/angular-ui/ui-scroll
     * @param {number} index - This is 1-based
     * @param {number} count
     * @param {function(Array):void} success
     */
    loadUsersInfiniteScroll(index, count, success) {
      // Determine whether the requested data has already been loaded, or if we need to grab it from API
      const users = this.users[this.userTypeFilter];
      let startIndex = index - 1;
      let endIndex = startIndex + count;

      if (startIndex < 0) {
        startIndex = 0;
      }

      if (endIndex < 0) {
        endIndex = 0;
      }

      if (!users.outdated && endIndex <= users.data.length) {
        success(users.data.slice(startIndex, endIndex));
        this.isHardReloading = false;
        this.isLoadingMoreResults = false;
      } else {
        if (!this.isHardReloading) {
          this.isLoadingMoreResults = true;
        }

        if (users.outdated || this.forceReload) {
          users.data = [];
        }

        let includeLearners = this.userTypeFilter === USER_TYPE_FILTERS.LEARNERS;
        let includeAdmins = CurrentPermissionsManager.hasFullCourseAdminPermissions() && this.userTypeFilter === USER_TYPE_FILTERS.ADMINS;
        let includeMentors = CurrentPermissionsManager.hasFullCourseAdminPermissions() && this.userTypeFilter === USER_TYPE_FILTERS.MENTORS;

        // Searches & filtering should look for all types of users except when the logged in user is Learner Registration Admin Only
        if (this.userTypeFilter === USER_TYPE_FILTERS.SEARCH || this.roleFilter != null) {
          includeAdmins = CurrentPermissionsManager.hasFullCourseAdminPermissions() && true;
          includeLearners = true;
          includeMentors = CurrentPermissionsManager.hasFullCourseAdminPermissions() && true;
        }

        // Set query params to 'null' to avoid including them in the request
        const searchQuery = this.isInSearch ? this.searchQuery : null;

        const requestData = (pageIndex) => this.requestUsers(includeLearners, includeAdmins, includeMentors, pageIndex, searchQuery);

        const pageIndex = Math.ceil(endIndex / TABLE_PAGE_SIZE);

        requestData(pageIndex).then(() => {
          success(users.data.slice(startIndex, endIndex));
        });
      }
    }

    requestUsers(includeLearners, includeAdmins, includeMentors, pageIndex, searchQuery) {
      const currentUserTypeFilter = this.userTypeFilter;

      return this.CurrentCourseManager.searchUsers(includeLearners, includeAdmins, includeMentors, pageIndex, TABLE_PAGE_SIZE, searchQuery, this.roleFilter)
        .then((result) => {
          const users = this.users[currentUserTypeFilter];

          // Skip past the users in the result page that are already in the local cache
          const resultStartindex = (pageIndex - 1) * TABLE_PAGE_SIZE;

          users.data.splice(resultStartindex, result.users.length, ...result.users);
          users.outdated = false;

          this.forceReload = false;
          this.isHardReloading = false;
          this.isLoadingMoreResults = false;

          if (this.isInSearch || this.roleFilter != null) {
            this.searchResultsCount = result.count;
            this.resultsTab[0].count = this.searchResultsCount ? this.searchResultsCount : 0;
          }
        });
    }

    doSearch(query) {
      this.users[USER_TYPE_FILTERS.SEARCH].outdated = true;
      this.isInSearch = true;
      this.searchQuery = query;
      // Hides the 'Search Results For' header
      this.searchResultsCount = 0;
      this.switchToSearchResults();
      this.forceReload = true;
      this.refreshTable();
    }

    onUserUploadedInModal(newlyEnrolled) {
      if (newlyEnrolled.some((user) => user.email === CurrentUserManager.user.email)) {
        // Unfortunately this my account reload is required given that enroll
        // request doesn't return new created enrollment and we need it for
        // current user
        CurrentUserManager.reinitialize();
      }

      this.switchToNonSearch({ roleFilter: undefined, initialState: false });
      this.refreshTable(true);
    }

    onUserUnenrolledInModal() {
      this.refreshTable(true);
      this.uibModalInstance.dismiss();
    }

    /** Set the UI to show the search results tabs and display search results (or a 'no results' view) */
    switchToSearchResults() {
      this.viewingSearchResults = true;

      if (this.$state.current.name !== this.getTabState('search')) {
        this.lastTabState = this.$state.current.name;
      }

      this.$state.go(this.getTabState('search'), { query: this.searchQuery });
    }

    /** Switch back to the normal, non-search view */
    switchToNonSearch(newParams) {
      this.nonSearchTabsVisible = true;
      this.isInSearch = false;
      this.searchQuery = '';
      this.viewingSearchResults = false;
      this.roleFilter = null;

      let resetStateParams = {};

      if (newParams) {
        resetStateParams = newParams;
      }
      if (this.lastTabState) {
        this.$state.go(this.lastTabState, resetStateParams);
      } else {
        this.$state.go(this.getTabState('learners'), resetStateParams);
      }
    }

    clearFilter() {
      // Reload the current state with no params to clear out the role id
      // You *must* use undefined here instead of null to avoid a crash in angular ui router
      this.$state.go(this.preFilterState, { roleFilter: undefined });
    }

    setCourseRoleEdit(user) {
      this.isEditing = true;
      this.editingId = user.id;
      this.courseRoleDraft = user.courseRole;

      if (!this.courseRoleDraft) {
        this.courseRoleDraft = CourseRolesManager.noRolesRole;
      }
    }

    cancelEdit() {
      this.isEditing = false;
      this.editingId = -1;
      this.courseRoleDraft = CourseRolesManager.noRolesRole;
    }

    /** @param {UserModel} user */
    saveEdit(user) {
      const editingOwnUser = this.CurrentUserManager.user.id === user.id;
      this.isEditSaving = true;

      // Show the 'confirm role change modal'
      let shouldShowConfirmModal = user.roles.courseRoleId && user.roles.courseRoleId !== CourseRolesManager.noRolesRole.id;

      // Do not show if we're switching between named learner roles
      if (shouldShowConfirmModal) {
        shouldShowConfirmModal = !(RolesService.isNamedLearnerRole(user.roles) && user.roles.permission === this.courseRoleDraft.permission);
      }

      // Do not show if we're switching from a named learner role to 'no roles', as named learners do not have extra permissions
      if (shouldShowConfirmModal) {
        shouldShowConfirmModal = !(RolesService.isNamedLearnerRole(user.roles) && this.courseRoleDraft.courseRoleId !== CourseRolesManager.noRolesRole.id);
      }

      if (editingOwnUser) {
        const modalInstance = this.ConfirmationOverlays.openConfirmationModal('user_management/templates/modify-own-user-confirm.html');
        modalInstance.result.then(() => {
          this.performSave(user, true);
        }).catch(() => {
          this.isEditSaving = false;
        });
      } else if (shouldShowConfirmModal) {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        const modalInstanceCtrl = ['$scope', ($scope) => {
          $scope.translationValues = this.getConfirmChangeRoleTranslationValues(user);
          $scope.isLossyChange = RolesService.isMentor(user.roles) && !RolesService.isMentor(this.courseRoleDraft);
        }];
        const modalInstance = this.ConfirmationOverlays.openConfirmationModal('user_management/templates/change-role-confirm.html', modalInstanceCtrl);
        modalInstance.result.then(() => {
          this.performSave(user, false);
        }).catch(() => {
          this.isEditSaving = false;
        });
      } else {
        this.performSave(user, false);
      }
    }

    /**
     * @param {UserModel} user
     * @param {bool} editingOwnUser - Whether you're editing the currenlty logged in user
     * */
    performSave(user, editingOwnUser) {
      this.CurrentCourseManager.course.enrollWithRole([user], this.courseRoleDraft.id).then(() => {
        // Refetch the current course if we're editing the current user, so CurrentPermissionsManager will have the latest permissions
        // Then redirect to course-home if the user just revoked their own access to the user mgmt page.
        if (editingOwnUser) {
          if (this.CurrentCourseManager.course.isJourney) {
            $scope.loadJourney();
          } else {
            this.CurrentCourseManager.requestCourse(this.CurrentCourseManager.course.catalogId, true)
              .then(() => {
                if (!this.CurrentPermissionsManager.hasCourseAdminPermissions()) {
                  this.$state.go('course-home');
                }
              });
          }
        }

        this.isEditSaving = false;
        this.cancelEdit();
        this.refreshTable(true);
      }).catch(() => {
        this.isEditSaving = false;
      });
    }

    modifyContentAccessEndDateClicked(user) {
      if (!this.lastTabState) {
        this.lastTabState = this.$state.current.name;
      }

      this.uibModalInstance = this.$uibModal.open({
        templateUrl: 'user_management/templates/modify-content-access-extension-modal.html',
        controller: 'ModifyContentAccessExtensionController as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet user-management-modal',
        resolve: {
          user,
        },
      });
    }

    modifyContentAccessEndDateIsVisible(user) {
      return (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isLearnerRegistrationRole())
        && RolesService.isLearner(user.roles) && CurrentCourseManager.course.isSelfPaced && CurrentCourseManager.course.enrollmentLimitInDays;
    }

    // eslint-disable-next-line class-methods-use-this
    resetPassword(user) {
      PasswordsResource.reset({
        catalogId: this.CurrentCourseManager.course.catalogId,
        user: {
          email: user.email,
        },
      }).$promise.then(() => {
        AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.RESET_PASSWORD_EMAIL_SENT', {}, { email: user.email });
      }).catch(() => {
        AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
      });
    }

    // eslint-disable-next-line class-methods-use-this
    resetPasswordIsVisible(user) {
      return (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || (user && CurrentPermissionsManager.isLearnerRegistrationRole() && RolesService.isLearner(user.roles))) && !InstitutionsManager.institution.ssoLogin;
    }

    resendWelcome(user) {
      UserManagementResources.sendWelcome({
        catalogId: this.CurrentCourseManager.course.catalogId,
        user: {
          email: user.email,
        },
      }).$promise.then(() => {
        AlertMessages.success('FORM.SUCCESS_BANG', 'USER_MANAGEMENT.WELCOME_EMAIL_SENT', {}, { email: user.email });
      }).catch(() => {
        AlertMessages.error('FORM.OOPS', 'FORM.ERROR_SOMETHING_WRONG');
      });
    }

    // eslint-disable-next-line class-methods-use-this
    resendWelcomeIsVisible(user) {
      return (CurrentPermissionsManager.isConfigAndRegistrationRole() || CurrentPermissionsManager.isInstructor() || (user && CurrentPermissionsManager.isLearnerRegistrationRole() && RolesService.isLearner(user.roles))) && CurrentCourseManager.course.welcomeEmailEnabled;
    }

    unenrollUser(user) {
      this.CurrentCourseManager.course.unenrollUserDialog(user)
        .then((res) => {
          CurrentUserManager.user.removeEnrollment(res.enrollmentId);

          store.dispatch(removeUserEnrollment({
            enrollmentId: res.enrollmentId,
            userId: CurrentUserManager.user.id,
          }));

          const currentRawUserData = cloneDeepSerializable(CurrentUserManager.user);
          CurrentUserManager.setCourses(currentRawUserData);
          CurrentUserManager.sortCourses();
          CurrentUserManager.setUserCourses(currentRawUserData);

          let users = this.getUsers();
          users = _.filter(users, (u) => u.id === user.id);
          this.refreshTable(true);
        });
    }

    isUnenrollEnabled(user) {
      return CurrentPermissionsManager.isConfigAndRegistrationRole() || (user && CurrentPermissionsManager.isLearnerRegistrationRole() && RolesService.isLearner(user.roles));
    }

    openEditNameAndEmailModal(user) {
      this.openEditNameAndEmail(user);
    }

    userIsAdmin(user) {
      return user.courseRole && RolesService.isAdminRole(user.courseRole);
    }

    userIsMentor(user) {
      return user.roles && RolesService.isMentor(user.roles);
    }

    /** Toggles whether a user appears in the Teaching Team Directory. Used by both the List and
     * Unlist options since the backend only supports toggling and not setting directly */
    toggleUserVisibility(user) {
      this.CurrentCourseManager.setCommunitySearchVisibility(user.id, !user.invisibleToSearch).then((result) => {
        user.invisibleToSearch = !user.invisibleToSearch;
      });
    }

    // Allows instructors to edit students in the search results
    isUserEditable(user) {
      return this.isEditEnabled || (user && CurrentPermissionsManager.isInstructor()
      && (RolesService.isLearner(user.roles) || RolesService.isMentor(user.roles))) || (user && CurrentPermissionsManager.isLearnerRegistrationRole()
      && RolesService.isLearner(user.roles));
    }

    rowClassForUser(user) {
      return user && this.userIsMentor(user) ? 'mentor-row' : 'non-mentor-row';
    }

    getMenteeName(user) {
      return user.roles.roles.menteeName;
    }

    menteeLinksVisible(user) {
      return this.isMentorLinkEnabled && this.userIsMentor(user);
    }

    /**
     * Opens manual assign modal
     */
    openManualAssignModal(user = null) {
      this.manuallyAddClicked(user);
    }

    /**
     * Opens bulk assign modal
     * @param {User?} user If null we assume we're uploading for multiple mentors
     */
    openBulkAssignModal(user = null) {
      this.openCSVUploadModal(user ? 2 : 3, user);
    }

    /**
     * Opens manage mentees modal and navigates to manual assign view
     */
    manuallyAssignClicked(user) {
      this.openMenteesModal(user, MANAGE_MENTEES_MODAL_VIEWS.MANUAL_ASSIGN);
    }

    /**
     * Opens manage mentees modal and navigates to bulk assign view
     */
    bulkAssignClicked(user) {
      this.openMenteesModal(user, MANAGE_MENTEES_MODAL_VIEWS.BULK_ASSIGN);
    }

    openMenteesModal(user, view = null) {
      this.uibModalInstance = $uibModal.open({
        templateUrl: 'user_management/templates/manage-mentees-modal.html',
        controller: 'ManageMenteesModalController as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet user-management-modal',
        resolve: {
          mentor: user,
          refreshTable: () => this.refreshTable.bind(this),
          view,
        },
      });
    }

    /** Shows the manually add modal.
     * @param {UserModel?} mentor Opens the mentor assignment modal if not null */
    manuallyAddClicked(mentor = null) {
      this.uibModalInstance = $uibModal.open({
        templateUrl: 'user_management/templates/manual-add-modal.html',
        controller: 'ManualAddUserModalController as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet user-management-modal',
        resolve: {
          mentor,
          onUserAdded: () => this.onUserUploadedInModal.bind(this),
        },
      });
    }

    uploadCSVClicked(parsingMode) {
      this.openCSVUploadModal(parsingMode);
    }

    /**
     * @param {number} parsingMode The kind of CSV to parse. See 'PARSING_MODES' in
     * upload-users-csv-controller.js
     * @param {User?} mentor The mentor to assign to. Only used in single-mentor mode
     */
    openCSVUploadModal(parsingMode, mentor = null) {
      this.uibModalInstance = this.$uibModal.open({
        templateUrl: 'user_management/templates/upload-csv-modal.html',
        controller: 'UploadUsersCsvController as vm',
        windowClass: 'full-screen-modal-handheld full-screen-modal-tablet user-management-modal',
        resolve: {
          onUserUploaded: () => this.onUserUploadedInModal.bind(this),
          onUserUnenrolled: () => this.onUserUnenrolledInModal.bind(this),
          parsingMode,
          mentor,
        },
      });
    }

    getConfirmChangeRoleTranslationValues(user) {
      const newRoleName = this.courseRoleDraft.id === 0 ? CurrentCourseManager.course.learnersName.capitalizedSingularized : this.courseRoleDraft.name;
      return _.extend(this.CurrentCourseManager.course.getAliases(), {
        currentRoleName: this.CourseRolesManager.roles[user.roles.courseRoleId].name,
        newRoleName,
      });
    }

    initialDropdownRole(user) {
      if (user.courseRole) {
        return user.courseRole;
      }

      return this.CourseRolesManager.noRolesRole;
    }

    initialFilterRole() {
      if (this.roleFilter === this.CourseRolesManager.noRolesRole.id) {
        return this.CourseRolesManager.noRolesRole;
      }

      return this.CourseRolesManager.roles[this.roleFilter];
    }
  }

  return new UserManagementMainController();
}
