import React, { useCallback, useContext, useEffect, useState } from 'react';
import t from 'react-translate';
import { without } from 'underscore';

import { AngularContext, AngularServicesContext } from 'react-app';
import SectionContent from '../section-content';
import { DisableProps } from './integrations';
import { config } from '../../../../../config/pendo.config.json';

const zoomOauthDisableArticleLink = 'https://help.novoed.com/hc/en-us/articles/360061031512';

const ZoomIntegration = (props: { onDisable: (props: DisableProps) => void }) => {
  const { $uibModal } = useContext(AngularServicesContext);
  const { injectServices, $scope } = useContext(AngularContext);
  const [InstitutionsManager] = injectServices(['InstitutionsManager']);
  const [source, setLiveSessionSettings] = useState($scope.$eval('vm.InstitutionsManager.institution.liveSessionSettings.source'));
  const [integrationType, setIntegrationType] = useState(false);

  useEffect(() => {
    $scope.$watch('vm.InstitutionsManager.institution.liveSessionSettings.source', (newValue) => {
      setLiveSessionSettings(newValue);
      if (source?.includes(InstitutionsManager.INTEGRATION_TYPES.ZOOM_ACCOUNT_LEVEL)) {
        setIntegrationType(InstitutionsManager.INTEGRATION_TYPES.ZOOM_ACCOUNT_LEVEL);
      } else if (source?.includes(InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL)) {
        setIntegrationType(InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL);
      } else {
        setIntegrationType(null);
      }
    });
  }, [$scope]);

  const hasIntegration = useCallback(() => {
    if (source?.includes(InstitutionsManager.INTEGRATION_TYPES.ZOOM_ACCOUNT_LEVEL)) {
      return true;
    } if (source?.includes(InstitutionsManager.INTEGRATION_TYPES.ZOOM_USER_LEVEL)) {
      return true;
    }
    return false;
  }, [source]);

  const configureZoom = () => {
    $uibModal.open({
      templateUrl: 'institutions/templates/configure-zoom-modal.html',
      controller: 'ConfigureZoomModalCtrl as vm',
      resolve: {
      },
    });
  };

  const disableZoom = () => {
    props.onDisable({
      action: () => InstitutionsManager.disableLiveSessionSettings(integrationType),
      onSuccess: () => InstitutionsManager.institution.updateFromReact({
        liveSessionSettings: {
          apiKey: '',
          apiSecret: '',
          source: without(
            InstitutionsManager.institution.liveSessionSettings.source,
            integrationType,
          ),
        },
        zoomEnabled: false,
      }),
      successMessage: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_SUCCESS(),
      title: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_INTEGRATION(),
      bodyText: t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM_DESCRIPTION(),
      confirmDataQa: config.pendo.settings.zoom.disableConfirm,
      cancelDataQa: config.pendo.settings.zoom.disableCancel,
    });
  };

  const zoomDescription = hasIntegration()
    && InstitutionsManager.institution?.liveSessionSettings?.source === 'zoom_account_oauth'
    ? t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_ACCOUNT_OAUTH_DESCRIPTION(zoomOauthDisableArticleLink)
    : t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_DESCRIPTION();

  return (
    <SectionContent
      header={t.INSTITUTIONS.ADVANCED_SETTINGS.ZOOM_INTEGRATION_TITLE()}
      description={[zoomDescription]}
      showCTAButton
      ctaText={hasIntegration() ? t.INSTITUTIONS.ADVANCED_SETTINGS.DISABLE_ZOOM() : t.INSTITUTIONS.ADVANCED_SETTINGS.CONFIGURE()}
      onAction={() => (hasIntegration() ? disableZoom() : configureZoom())}
      buttonVariant={hasIntegration() ? 'secondary' : 'primary'}
      dataQa={config.pendo.settings.zoom.cta}
    />
  );
};

export default ZoomIntegration;
